<template>
  <moe-page title="新增品牌">
    <moe-form
      ref="brandForm"
      :showBack="false"
      :model="brandParams"
      :rules="rules">
      <el-form-item label="品牌名称" prop="name">
        <el-input v-model.trim="brandParams.name" placeholder="请输入品牌名称" clearable maxlength="16"></el-input>
      </el-form-item>

      <el-form-item label="品牌图标" prop="icon">
        <moe-upload-file
          v-model="brandParams.icon"
          ossKey="SHOP_MEDIA"
          upload-text="上传品牌图标"
          :default-file-list="iconList"
          @change="() => $refs.brandForm.validateField('icon')" />
      </el-form-item>

      <el-form-item label="品牌介绍" prop="introduce">
        <el-input type="textarea" :rows="5" placeholder="请输入品牌介绍" maxlength="100" clearable v-model="brandParams.introduce"></el-input>
      </el-form-item>

      <template slot="tool">
        <el-button type="info" icon="el-icon-close" @click="$moe_coordinator.navigateBack()">关闭</el-button>
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="paramsLoad"
          @click="submit">
          {{ paramsLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'ContentManageBrandManageAdd',
  computed: {
    IS_EDIT() {
      if (this.brandParams.id) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    const verify = this.$moe_verify.verifyForm
    const rules = {
      name: verify.isExtent(['请输入品牌名称', '长度为 2 ~ 16位'], '2~16'),
      icon: verify.isEmpty('请上传品牌图标'),
      introduce: verify.isExtent(['请输入品牌介绍', '长度为 1 ~ 100位'], '1~100'),
    }
    return {
      brandParams: {
        id: '',
        name: '',
        icon: '',
        introduce: '',
        status: false,
      },
      iconList: [],
      rules,
      paramsLoad: false,
    }
  },
  methods: {
    submit() {
      this.$refs['brandForm'].validate(() => {
        this.paramsLoad = true;
        if (this.IS_EDIT) {
          this.$moe_api.BRAND_API.updateBarnd(this.brandParams).then((data) => {
            if (data.code === 200) {
              this.$moe_msg.success('编辑成功', {
                completionHandler: () => {
                  this.$moe_coordinator.navigateBack();
                }
              });
            } else {
              this.$moe_msg.error(data.message);
            }
          }).finally(() => {
            this.paramsLoad = false;
          })
        } else {
          this.$moe_api.BRAND_API.addBrand(this.brandParams).then((data) => {
            if (data.code === 200) {
              this.$moe_msg.success('添加成功', {
                completionHandler: () => {
                  this.$moe_coordinator.navigateBack();
                }
              });
            } else {
              this.$moe_msg.error(data.message);
            }
          }).finally(() => {
            this.paramsLoad = false;
          })
        }
      })
    },
    getBrandDetail() {
      if (this.$route.query.id) {
        this.$moe_api.BRAND_API.getBrandDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let r = data.result;
            this.brandParams = {
              id: r.id,
              name: r.name,
              icon: this.$moe_yunHelper.formatterImageUrl(r.icon),
              introduce: r.introduce,
              status: r.status,
            }
            if (r.icon) {
              this.iconList = [{
                url: r.icon,
                tempFilePath: this.$moe_yunHelper.getTempFilePath(r.icon)
              }]
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })

      }
    }
  },
  mounted() {
    this.getBrandDetail();
  },
}
</script>